#main-keygen {
    font-size: .875rem;
    margin: 0 auto;
    padding: 0 0.25rem;
}
.fc1 {
    height: 6rem !important;
    padding: 0 0.75rem !important;
}
.fc2 {
    height: 16rem !important;
    overflow-x: hidden !important;
}
.result {
    height: 40vh;
    overflow-y: auto;
    overflow-x: auto;
}
.line {
    font-family: monospace;
    white-space:  nowrap;
}
.inp {
    width: 20%;
}

/* From bootstrap */
.right-0 {right: 0 !important;}
#main {
    max-width: 500px;
    margin: 0 auto;
    padding: 0 0.25rem;
    font-size: 0.875rem;
    
}

.fc {
    height: auto !important;
    padding: 0 0.75rem !important;
}
.border_bottom {
    border-bottom: lightskyblue 2px solid
}
.input-file {
    position: absolute;     
    opacity: 0;
}
.picture {
    width: 30%;
}
.opacity0 {opacity: 0%}
.opacity100 {
    opacity: 100%;
    transition-property: opacity;
    transition-duration: 1000ms;
    /* transition-timing-function: ease-out; */
}
.clRefresh {
    height: 1.2rem;
    margin-left: auto;
}
.clIReturn {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 20;
    height: 1.2rem;
}
.clTReturn {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 20;

    border: none;
    color: blue;
    font-weight: bold;
    background-color: transparent;
}
.clTLink {
    position: absolute;
    z-index: 20;
    left: 50%;
    transform: translate(-50%, 0);

    border: none;
    color: blue;
    font-weight: bold;
    background-color: transparent;
}

.field-check {
    font-family: monospace;
}
button:focus,
input:focus
{
    outline: none;
}

/*----- QRcode row ----*/
.clRowQR {
    display: flex;
    position: relative;
    padding: 5px 15px;
}
.clQR {
    width: 15%;
    height: auto;
}
.clQRsign {
    border: none;
    background-color: transparent;
    width: 24%;
    font-weight: bold;
    font-size: 1.5rem;
}
.clQRminus {
    width: 27.5%;
}
.clQRequel {
    width: 27.5%;
}
.clQRlogo {
    position: absolute;
    bottom: 4px;
    right: 57.5%;
    color: red;
    font-weight: bold;
    text-align: right;
}
.clQRshift {
    margin-left: 42.5%;
}
/*----- Label row -----*/
.clLabelHash,
.clLabelOpen,
.clLabelDiff{
    margin-top: -5px;
    line-height: 1;
    width: calc(15% + 30px);
    overflow: visible;
    vertical-align: top;
    text-align: center;
}
.clLabelOpen {margin-left: calc(27.5% - 45px);}
.clLabelDiff {margin-left: calc(27.5% - 45px);}

@keyframes change_color {
    50% {color: red;}
}
.clInfo {
    /* font-size: 1rem;
    padding: 0.375rem 0.25rem; */
    font-size: .875rem;
    padding: 0.25rem;
    line-height: 1.2;
    
    text-align: center;
    border-radius: 0.25rem;
    white-space: pre-line;

    color: green;
    background-color: yellow;
    animation: change_color 2000ms step-start infinite;
}
.clSecret {
    color: yellow !important;
}
.height-big {
    padding: 40px 0 !important;
}

/*----- Header row -----*/
.header {
    font-size: 1.00rem;
    font-weight: 700;
    margin-bottom: 0.25rem;

    line-height: 1.2;
    text-align: center;
    white-space: pre-line;
}

/*----- color classes -----*/
.t-red {color: red}
.t-green {color: green}
.t-yellow {color: yellow !important;}
.bg-blue {background: #20a8d8 !important;}
